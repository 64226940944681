'use client';

import {
  ArrowPathIcon,
  ClipboardDocumentListIcon,
  UserGroupIcon,
  CubeIcon,
  ChartBarIcon,
  ShieldCheckIcon,
  BuildingOfficeIcon,
  BellIcon,
  ArrowsRightLeftIcon,
  CalendarIcon,
  ClockIcon,
  ListBulletIcon
} from '@heroicons/react/24/outline';

const features = [
  {
    name: 'NFPA 1500 Compliance Tracking',
    description:
      'Ensure your department meets all NFPA 1500 standards with our comprehensive tracking system.',
    icon: ClipboardDocumentListIcon
  },
  {
    name: 'Staff Certification Tracking',
    description:
      'Keep track of all staff certifications and expirations in one centralized location.',
    icon: ArrowPathIcon
  },
  {
    name: 'Equipment Inventory & Maintenance',
    description:
      'Manage your equipment inventory and maintenance schedules efficiently.',
    icon: CubeIcon
  },
  {
    name: 'Staff OSHA Compliance Tracking',
    description:
      'Ensure your staff meets all OSHA requirements with our compliance tracking feature.',
    icon: ShieldCheckIcon
  },
  {
    name: 'Facilities Safety Inspections',
    description:
      'Schedule and log safety inspections for facilities to ensure compliance and safety.',
    icon: BuildingOfficeIcon
  },
  {
    name: 'Vacancy Shift Fill & Notifications',
    description:
      'Quickly fill shift vacancies (sick, OT, events) with staff notifications via text and email.',
    icon: BellIcon
  },
  {
    name: 'Shift Trades',
    description:
      'Easily manage shift trades between staff with automated approvals and notifications.',
    icon: ArrowsRightLeftIcon
  },
  {
    name: 'Shift Schedules',
    description:
      'Plan and organize staff shift schedules with clarity and flexibility.',
    icon: CalendarIcon
  },
  {
    name: 'Staff Availability',
    description:
      'Track staff availability in real-time to manage scheduling efficiently.',
    icon: ClockIcon
  },
  {
    name: 'Daily Plans',
    description:
      'Automatically generate daily plans for staff based on availability and shift schedules with scheduled items, training, construction, flag status, weather, etc.',
    icon: CalendarIcon
  },
  {
    name: 'Task Assignments with Due Dates',
    description:
      'Assign tasks to staff with specific due dates and monitor progress to completion.',
    icon: ListBulletIcon,
  }
];

export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-fire-red-400">
            Comprehensive Solution
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Features That Make a Difference
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our SaaS platform offers a complete suite of tools to help fire
            departments manage, safety, and compliance efficiently.  Our features will also handle all of your scheduling needs include trades and shift vacancy plans.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-fire-red-400">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
