export const ROUTES = {
  HOME: '/dashboard',
  DASHBOARD: '/dashboard',
  COMPLIANCE: {
    BASE: '/compliance',
    DETAIL: (id: string) => `/compliance/${id}`
  },
  FACILITIES: {
    BASE: '/facilities',
    DETAIL: (id: string) => `/facilities/${id}`,
    INSPECTIONS: {
      BASE: '/facilities/inspections',
      DETAIL: (id: string) => `/facilities/inspections/${id}`,
      NEW: '/facilities/inspections/new'
    }
  },
  EQUIPMENT: {
    BASE: '/equipment',
    DETAIL: (id: string) => `/equipment/${id}`,
    NEW: '/equipment/new',
    TYPES: {
      BASE: '/equipment/equipment-types',
      DETAIL: (id: string) => `/equipment/equipment-types/${id}`,
      NEW: '/equipment/equipment-types/new'
    },
    INSPECTIONS: {
      BASE: '/equipment/inspections',
      DETAIL: (id: string) => `/equipment/inspections/${id}`,
      NEW: '/equipment/inspections/new',
      UPCOMING: '/equipment/inspections/upcoming'
    },
    SCHEDULES: {
      BASE: '/equipment/inspection-schedules',
      DETAIL: (id: string) => `/equipment/inspection-schedules/${id}`,
      NEW: '/equipment/inspection-schedules/new'
    }
  },
  SHIFTS: {
    BASE: '/shifts',
    DETAIL: (id: string) => `/shifts/${id}`,
    NEW: '/shifts/new',
    TRADES: {
      BASE: '/shifts/trades',
      DETAIL: (id: string) => `/shifts/trades/${id}`,
      NEW: '/shifts/trades/new'
    },
    FACILITY_ASSIGNMENTS: {
      BASE: '/shifts/facility-assignments',
      DETAIL: (id: string) => `/shifts/facility-assignments/${id}`,
      NEW: '/shifts/facility-assignments/new'
    },
  },
  STAFF: {
    BASE: '/staff',
    DETAIL: (id: string) => `/staff/${id}`,
    NEW: '/staff/new',
    SHIFTS: '/staff/shifts',
    SCHEDULE: {
      BASE: '/staff/shifts/schedule',
      DETAIL: (id: string) => `/staff/shifts/schedule/${id}`,
      NEW: '/staff/shifts/schedule/new'
    },
    VACANCY: {
      BASE: '/staff/vacancy',
      DETAIL: (id: string) => `/staff/vacancy/${id}`,
      NEW: '/staff/vacancy/new'
    },
    AVAILABILITY: '/staff/availability',
    CERTIFICATIONS: {
      BASE: '/staff/certifications',
      OVERVIEW: '/staff/certifications/overview',
      TYPES: {
        BASE: '/staff/certifications/certification-types',
        DETAIL: (id: string) =>
          `/staff/certifications/certification-types/${id}`,
        NEW: '/staff/certifications/certification-types/new'
      }
    },
    POSITIONS: {
      BASE: '/staff/positions',
      DETAIL: (id: string) => `/staff/positions/${id}`,
      NEW: '/staff/positions/new'
    }
  },
  MIOSHA: {
    BASE: '/osha/compliance',
    OVERVIEW: '/osha/compliance/overview',
    TRAINING_OPTIONS: '/osha/training-options'
  },
  DOCUMENTS: {
    BASE: '/documents',
    DETAIL: (id: string) => `/documents/${id}`
  },
  CALLS: '/calls',
  TASK_ASSIGNMENTS: {
    BASE: '/task-assignments',
    DETAIL: (id: string) => `/task-assignments/${id}`,
    NEW: '/task-assignments/new'
  },
  INVITATIONS: '/invitations',
};

// route constants
export const loginRoute = '/login';
export const newRoute = '/new';
